import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, ROUTE } from '@enx/shared/util/enums';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '@enx/config/data-access';

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

interface Login {
  accessToken: string;
  refreshToken: string;
  isSuccess: boolean;
  message: string;
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class AuthDataAccessService {
  private readonly RESOURCE_URL = `${this.config.apiUrl}/user`;

  constructor(private http: HttpClient, private router: Router, private config: ConfigService) {}

  login(email: string, password: string, isFirstLogin = false): Observable<Login> {
    return this.http.post<Login>(`${this.RESOURCE_URL}/login`, { email, password }, { withCredentials: true }).pipe(
      tap(({ accessToken, user }) => {
        this.setAccessToken(accessToken);
        this.setUserName(user);
        this.setRefreshTokenExpirationDate();
        isFirstLogin
          ? this.router.navigate([ROUTE.APP], { state: { firstLogin: true } })
          : this.router.navigate([ROUTE.APP]);
      }),
    );
  }

  refreshToken(): Observable<{ accessToken: string }> {
    return this.http
      .post<{ accessToken: string }>(`${this.RESOURCE_URL}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        tap(({ accessToken }) => {
          this.setAccessToken(accessToken);
        }),
      );
  }

  isLoggedIn(): boolean {
    return !this.isTokenExpired();
  }

  logout(): Observable<unknown> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return of();
    }

    return this.http.post(`${this.RESOURCE_URL}/logout`, { accessToken }).pipe(tap(() => this.clearLocalStorage()));
  }

  isTokenExpired(): boolean {
    const token = this.getAccessToken();
    if (!token) {
      return true;
    }

    const tokenData = this.parseToken(token);

    if (!tokenData || !tokenData.exp) {
      return true;
    }

    const expirationDate = new Date(tokenData.exp * 1000);
    const currentDate = new Date();

    return currentDate > expirationDate;
  }

  getAccessToken(): string | null {
    return localStorage.getItem(LocalStorage.AUTH_TOKEN);
  }

  resetPassword(email: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.RESOURCE_URL}/reset-password`, { email });
  }

  setPassword(token: string, newPassword: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.RESOURCE_URL}/set-password`, { token, newPassword }).pipe(
      tap(() => {
        this.router.navigate([ROUTE.LOGIN]);
      }),
    );
  }

  firstLogin(token: string, newPassword: string): Observable<Login> {
    return this.http
      .post<{ email: string; isSuccess: boolean; message: string }>(`${this.RESOURCE_URL}/set-password`, {
        token,
        newPassword,
      })
      .pipe(
        switchMap((response) => {
          if (response.isSuccess) {
            return this.login(response.email, newPassword, true);
          } else {
            return of();
          }
        }),
      );
  }

  isRefreshTokenExpired(): boolean {
    const expirationDateStr = localStorage.getItem(LocalStorage.EXPIRATION_DATE);
    if (!expirationDateStr) {
      return true;
    }

    const expirationDate = parseInt(expirationDateStr, 10);
    const currentDate = new Date().getTime();

    return currentDate > expirationDate;
  }

  private clearLocalStorage(): void {
    localStorage.clear();
  }

  private parseToken(token: string): { exp: number } | null {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Failed to parse token:', error);
      return null;
    }
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(LocalStorage.AUTH_TOKEN, token);
  }

  private setUserName(user: User): void {
    localStorage.setItem(LocalStorage.USER_NAME, user.firstName);
  }

  private setRefreshTokenExpirationDate(): void {
    const currentDate = new Date();
    const datePlus7Days = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    const unixTimestamp = datePlus7Days.getTime();
    localStorage.setItem(LocalStorage.EXPIRATION_DATE, unixTimestamp.toString());
  }
}
