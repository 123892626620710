import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@enx/shared/util/enums';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';

import { AuthDataAccessService } from '../auth-data-access.service';

@Injectable()
export class AuthDataAccessTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthDataAccessService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('login') &&
      !request.url.includes('refresh') &&
      !request.url.includes('reset-password') &&
      !request.url.includes('set-password') &&
      !request.url.includes('register') &&
      !request.url.includes('local-partner') &&
      request.url.includes('/api')
    ) {
      if (this.authService.isTokenExpired()) {
        return this.handleTokenRefresh(request, next);
      } else {
        const token = this.authService.getAccessToken();
        return next.handle(this.addTokenHeader(request, token));
      }
    }
    return next.handle(request);
  }

  private handleTokenRefresh(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isRefreshTokenExpired()) {
      this.router.navigate([ROUTE.LOGIN]);
      return throwError(() => new Error('Refresh token expired'));
    }
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          const token = this.authService.getAccessToken();
          this.refreshToken$.next(token);
          return next.handle(this.addTokenHeader(request, token));
        }),
        finalize(() => {
          this.isRefreshing = false;
        }),
        catchError((error) => {
          this.router.navigate([ROUTE.LOGIN]);
          console.error('Token refresh failed', error);
          return throwError(() => new Error('Token refresh failed'));
        }),
      );
    } else {
      return this.refreshToken$.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addTokenHeader(request, token));
        }),
      );
    }
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: string | null): HttpRequest<unknown> {
    return token
      ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        })
      : request;
  }
}
